.ant-modal-close {
  display: none;
}

.btn-edit-cate {
  font-size: 22px;
  margin-left: 10px;
  color: #f9623e;
  cursor: pointer;
}
.ant-notification-notice-message {
  margin-bottom: 0 !important;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(67, 67, 67, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 999999;
  user-select: none;
  .ant-spin-dot-item {
    background-color: #26ff00 !important;
  }
}
