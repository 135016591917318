*,
*::before,
*::after {
  box-sizing: border-box;
}
@font-face {
  font-family: 'Lexend';
  // src: url("../fonts/Lexend-Thin.ttf");
  src: url('../../fonts/Lexend-Thin.ttf');
  font-weight: 100;
}
@font-face {
  font-family: 'Lexend';
  src: url('../../fonts/Lexend-ExtraLight.ttf');
  font-weight: 200;
}
@font-face {
  font-family: 'Lexend';
  src: url('../../fonts/Lexend-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'Lexend';
  src: url('../../fonts/Lexend-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Lexend';
  src: url('../../fonts/Lexend-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Lexend';
  src: url('../../fonts/Lexend-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Lexend';
  src: url('../../fonts/Lexend-Bold.ttf');
  font-weight: 700;
}
body {
  font-family: 'Lexend', sans-serif;
}
a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
input,
textarea {
  outline: 0;
  font-family: 'Lexend', sans-serif;
}
button {
  font-family: 'Lexend', sans-serif;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: 0;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  @include mq_min_576 {
    max-width: 540px;
  }
  @include mq_min_768 {
    max-width: 720px;
    // padding: 0 25px 50px;
  }
  @include mq_min_992 {
    max-width: 960px;
    // padding: 0 25px 50px;
  }
  @include mq_min_1280 {
    max-width: 1140px;
    // padding: 0 25px 50px;
  }
  @include mq_min_1400 {
    max-width: 1280px;
    // padding: 0 25px 50px;
  }
}
