.wrapper {
  .header {
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: #fff;
    box-shadow: 0px 2px 8px #dbdbdb;
    .header-container {
      @include flex(space-between, center);
      padding: 16px 24px;
      .header-logo {
        @include flex(flex-start, center);
        .header-logo--image {
          width: 69px;
          height: 44px;
          margin-right: 9px;
        }
        .header-logo--text {
          @include set_font(24px, 700, 36px);
        }
      }
      .header-menu {
        @include flex(flex-start, center);
        .header-menu--action {
          .header-action--icon {
            padding-right: 16px;
            font-size: 18px;
            color: #9095a1;
            transition: all 0.3s ease;
            &:hover {
              color: #000;
            }
          }
        }
        .header-menu--line {
          width: 32px;
          border: 1px solid #dee1e6;
          transform: rotate(90deg);
        }
        .header-menu--profile {
          @include flex(flex-start, center);
          .header-profile--avt {
            width: 36px;
            height: 36px;
            margin-right: 16px;
            border-radius: 18px;
            overflow: hidden;
            background-color: #d3c1fa;
          }
          .header-profile--action {
            cursor: pointer;
            .header-profile--name {
              margin-right: 10px;
              @include set_font(16px, 300, 26px);
            }
            .header-profile--icon {
              color: #9095a1;
            }
          }
        }
      }
    }
  }
  .content {
    min-height: 100vh;
    @include flex(flex-start, normal);
    .sidebar {
      width: 250px;
      box-shadow: 0px 10px 14px #dbdbdb;
      background-color: #fafafb;
      .sidebar-container {
        position: fixed;
        width: inherit;
        padding: 22px 14px 24px;
        @include set_font(16px, 300, 26px);
        .sidebar-item {
          margin-bottom: 15px;
          padding: 16px;
          border-radius: 4px;
          color: #565d6d;
          transition: all 0.3s ease;
          cursor: pointer;
          &:hover {
            background-color: #d3c1fa;
          }
          .sidebar-item--name {
            padding-left: 10px;
          }
        }
        .sidebar-item.selected {
          font-weight: 700;
          color: #6d31ed;
          background-color: #d3c1fa;
          transition: all 0.3s ease;
        }
      }
    }
    .main-content {
      width: calc(100% - 250px);
      padding: 22px 34px;
      .crawl-form {
        padding: 24px 29px 46px;
        border: 1px solid #dee1e6;
        border-radius: 6px;
        background-color: #fafafb;
        .crawl-form--header {
          margin-bottom: 24px;
          @include set_font(30px, 700, 46px);
        }
        .crawl-form--content {
          .crawl-form--input {
            margin-bottom: 20px;
            padding: 15px;
            border-radius: 6px;
            box-shadow: 3px 3px 8px #dbdbdb;
            .crawl-input--field {
              width: 100%;
              padding: 12px;
              border: 1px solid #9095a1;
              border-radius: 6px;
              @include set_font(14px, 400, 22px);
              transition: all 0.3s ease;
              &:hover {
                border-color: #000;
              }
              &:focus {
                border-color: #000;
              }
              &:disabled {
                color: #bdc1ca;
              }
            }
          }
          .crawl-form--submit {
            margin-top: 40px;
            .crawl-submit--button {
              padding: 5px 15px;
              border-radius: 6px;
              @include set_font(18px, 400, 28px);
              background-color: #6d31ed;
              color: #fff;
              transition: all 0.3s ease;
              &:hover {
                background-color: #5113d7;
              }
              .crawl-button--text {
                margin-left: 5px;
              }
            }
          }
        }
      }
      .crawl-image {
        margin-top: 40px;
        padding: 24px 29px 46px;
        border: 1px solid #dee1e6;
        border-radius: 6px;
        background-color: #fafafb;
        .crawl-image--header {
          margin-bottom: 24px;
          @include set_font(30px, 700, 46px);
          .crawl-header--hashtag {
            color: #6d31ed;
          }
        }
        .crawl-image--content {
          .crawl-image--list {
            @include flex(flex-start, center);
            flex-wrap: wrap;
            margin-right: -35px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            .crawl-image--item {
              width: calc(20% - 35px);
              height: 290px;
              margin: 0 35px 24px 0;
              border: 1px solid #dee1e6;
              border-radius: 6px;
              box-shadow: 0 4px 9px #dbdbdb;
              overflow: hidden;
              // &:not(:last-child) {
              // }
            }
          }
        }
        .crawl-image--pagination {
          @include flex(flex-end, center);
          margin-top: 40px;
          .crawl-pagination--item {
            &:not(:last-child) {
              margin-right: 4px;
            }
            .pagination-item--control {
              padding: 10px;
              @include set_font(14px, 300, 22px);
              color: #424856;
              .pagination-item--icon {
                font-size: 16px;
              }
            }
            .pagination-item--number {
              width: 36px;
              padding: 10px;
              border: 1px solid #dee1e6;
              border-radius: 4px;
              color: #9095a1;
              transition: all 0.3s ease;
              &:hover {
                border: 1px solid #6d31ed;
                background-color: #6d31ed;
                color: #fff;
              }
            }
            .pagination-item--number.active {
              border: 1px solid #6d31ed;
              background-color: #6d31ed;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
