.wrapper {
  .header {
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: #fff;
    box-shadow: 0px 2px 8px #dbdbdb;
    .header-container {
      @include flex(space-between, center);
      padding: 16px 24px;
      .header-logo {
        @include flex(flex-start, center);
        .header-logo--image {
          width: 69px;
          height: 44px;
          margin-right: 9px;
        }
        .header-logo--text {
          @include set_font(24px, 700, 36px);
        }
      }
      .header-menu {
        @include flex(flex-start, center);
        .header-menu--action {
          .header-action--icon {
            padding-right: 16px;
            font-size: 18px;
            color: #9095a1;
            transition: all 0.3s ease;
            &:hover {
              color: #000;
            }
          }
        }
        .header-menu--line {
          width: 32px;
          border: 1px solid #dee1e6;
          transform: rotate(90deg);
        }
        .header-menu--profile {
          @include flex(flex-start, center);
          .header-profile--avt {
            width: 36px;
            height: 36px;
            margin-right: 16px;
            border-radius: 18px;
            overflow: hidden;
            background-color: #d3c1fa;
          }
          .header-profile--action {
            cursor: pointer;
            .header-profile--name {
              margin-right: 10px;
              @include set_font(16px, 300, 26px);
            }
            .header-profile--icon {
              color: #9095a1;
            }
          }
        }
      }
    }
  }
  .content {
    @include flex(flex-start, normal);
    .sidebar {
      width: 250px;
      // padding: 22px 14px 24px;
      box-shadow: 0px 10px 14px #dbdbdb;
      background-color: #fafafb;
      .sidebar-container {
        @include set_font(16px, 300, 26px);
        .sidebar-item {
          margin-bottom: 15px;
          padding: 16px;
          border-radius: 4px;
          color: #565d6d;
          transition: all 0.3s ease;
          cursor: pointer;
          &:hover {
            background-color: #d3c1fa;
          }
          .sidebar-item--name {
            padding-left: 10px;
          }
        }

        .sidebar-item.active {
          color: #6d31ed;
          background-color: #d3c1fa;
        }

        .sidebar-item.selected {
          font-weight: 700;
          color: #6d31ed;
          background-color: #d3c1fa;
          transition: all 0.3s ease;
        }
      }
    }
    .main-content {
      width: calc(100% - 250px);
      // margin-left: 30%;
      padding: 22px 34px;
      .create-box {
        padding: 29px 24px;
        border: 1px solid #dee1e6;
        border-radius: 6px;
        background-color: #fafafb;
        .create-box--header {
          margin-bottom: 24px;
          @include set_font(30px, 700, 46px);
          color: #323743;
        }
        .create-box--content {
          .create-box--category {
            .create-category {
              @include flex(space-between, center);
              .add-category {
                width: calc(100% - 185px);
                margin-right: 10px;
                padding: 10px 13px;
                border-radius: 6px;
                background-color: #fff;
                box-shadow: 0px 0px 5px 0px #dbdbdb;
                .create-category--input {
                  width: 100%;
                  padding: 8px 12px;
                  border: 1px solid #9095a1;
                  border-radius: 6px;
                  @include set_font(14px, 400, 22px);
                  transition: all 0.3s ease;
                  &:hover {
                    border-color: #000;
                  }
                  &:focus {
                    border-color: #000;
                  }
                  &:disabled {
                    color: #bdc1ca;
                  }
                }
              }
              .add-label {
                width: 165px;
                @include flex(center, center);
                padding: 8px;
                border-radius: 6px;
                @include set_font(18px, 300, 28px);
                background-color: #6d31ed;
                color: #fff;
                transition: all 0.3s ease;
                &:hover {
                  background-color: #5113d7;
                }
                &:disabled {
                  opacity: 0.4;
                }
                .add-label--icon {
                  margin-right: 5px;
                }
              }
            }
            .add-label--list {
              margin: 14px 0 14px 54px;
              .add-label--item {
                @include flex(flex-start, center);
                margin-bottom: 15px;
                .add-label--input {
                  width: calc(100% - 185px);
                  margin-right: 10px;
                  padding: 10px 13px;
                  border-radius: 6px;
                  box-shadow: 0px 0px 5px 0px #dbdbdb;
                  background-color: #fff;
                  .add-label--field {
                    width: 100%;
                    padding: 8px 12px;
                    border: 1px solid #9095a1;
                    border-radius: 6px;
                    @include set_font(14px, 400, 22px);
                    transition: all 0.3s ease;
                    &:hover {
                      border-color: #000;
                    }
                    &:focus {
                      border-color: #000;
                    }
                    &:disabled {
                      color: #bdc1ca;
                    }
                  }
                }
                .remove-label {
                  width: 165px;
                  padding: 0 12px;
                  @include flex(flex-start, center);
                  .remove-label--icon {
                    @include set_font(32px, 400, unset);
                    color: #de3b40;
                  }
                }
              }
            }
          }
          .add-label--submit {
            @include flex(center, center);
            margin-top: 24px;
            .add-label--submitbtn {
              padding: 5px 12px;
              border-radius: 6px;
              @include set_font(18px, 300, 28px);
              background-color: #6d31ed;
              color: #fff;
              transition: all 0.3s ease;
              &:hover {
                background-color: #5113d7;
              }
              .submitbtn--icon {
                padding-right: 10px;
              }
            }
          }
        }
      }
      .update-box {
        margin-top: 34px;
        padding: 29px 24px;
        border: 1px solid #dee1e6;
        border-radius: 6px;
        background-color: #fafafb;
        .update-box--header {
          @include set_font(30px, 700, 46px);
        }
        .update-box--form {
          margin-top: 39px;
          .update-form--list {
            .update-form--item {
              .update-form--category {
                @include flex(flex-start, center);
                .custom-checkbox {
                  width: 24px;
                  height: 24px;
                  @include flex(center, center);
                  margin-right: 10px;
                  border: 1px solid #565d6d;
                  border-radius: 3px;
                  background-color: #fff;
                  .icon-checkbox {
                    color: #fff;
                  }
                }
                .custom-checkbox.checked {
                  border: 1px solid #5113d7;
                  background-color: #5113d7;
                }
                .update-item--text {
                  margin-right: 20px;
                  @include set_font(18px, 300, 42px);
                }
                .update-item--edit {
                  .icon-edit {
                    font-size: 18px;
                    color: #f9623e;
                  }
                }
              }
              .update-form--label {
                margin: 9px 0 34px 40px;
                @include flex(flex-start, center);
                .update-label {
                  @include flex(flex-start, center);
                  &:not(:last-child) {
                    margin-right: 20px;
                  }
                  .custom-checkbox {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    @include set_font(18px, 300, 20px);
                    user-select: none;
                    padding-left: 30px;
                    margin-bottom: 10px;
                    .label-checkbox {
                      position: absolute;
                      opacity: 0;
                      cursor: pointer;
                      width: 0;
                      height: 0;
                    }
                    .checkmark {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 20px;
                      height: 20px;
                      border: 1px solid #565d6d;
                      border-radius: 3px;
                      background-color: #fff;
                      transition: all 0.3s ease;
                      .icon-checkbox {
                        opacity: 0;
                      }
                    }
                    .checkmark.checked {
                      @include flex(center, center);
                      border: 1px solid #5113d7;
                      background-color: #5113d7;
                      .icon-checkbox {
                        opacity: 1;
                        font-weight: 300;
                        color: #fff;
                      }
                    }
                    .label-checkbox:checked ~ .checkmark {
                      @include flex(center, center);
                      border: 1px solid #5113d7;
                      background-color: #5113d7;
                      .icon-checkbox {
                        opacity: 1;
                        font-weight: 300;
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
          .update-form--submit {
            @include flex(center, center);
            .update-form--submitbtn {
              padding: 5px 12px;
              border-radius: 6px;
              @include set_font(18px, 300, 28px);
              background-color: #6d31ed;
              color: #fff;
              transition: all 0.3s ease;
              &:hover {
                background-color: #5113d7;
              }
              .update-icon {
                padding-right: 10px;
              }
            }
          }
        }
      }

      .product-info {
        padding: 24px 29px 46px;
        border: 1px solid #dee1e6;
        border-radius: 6px;
        background-color: #fafafb;
        .product-info--header {
          margin-bottom: 24px;
          @include set_font(30px, 700, 46px);
        }
        .product-info--content {
          height: 300px;
          @include flex(start, normal);
          border-radius: 6px;
          box-shadow: 0 3px 8px #dbdbdb;
          overflow: hidden;
          .product-content--image {
            width: 40%;
          }
          .product-content--detail {
            width: 60%;
            padding: 30px;
            .product-detail--title {
              max-width: 400px;
              margin-bottom: 20px;
              @include truncate_text(1);
              @include set_font(24px, 600, 36px);
            }
            .product-detail--description {
              @include truncate_text(5);
              @include set_font(20px, 300, 30px);
            }
          }
        }
      }
      .product-update {
        margin-top: 34px;
        padding: 29px 24px;
        border: 1px solid #dee1e6;
        border-radius: 6px;
        background-color: #fafafb;
        .product-update--header {
          @include set_font(30px, 700, 46px);
        }
        .product-update--form {
          margin-top: 39px;
          .update-form--list {
            .update-form--item {
              .update-form--category {
                @include flex(flex-start, center);
                .custom-checkbox {
                  width: 24px;
                  height: 24px;
                  @include flex(center, center);
                  margin-right: 10px;
                  border: 1px solid #565d6d;
                  border-radius: 3px;
                  background-color: #fff;
                  .icon-checkbox {
                    color: #fff;
                  }
                }
                .custom-checkbox.checked {
                  border: 1px solid #5113d7;
                  background-color: #5113d7;
                }
                .update-item--text {
                  margin-right: 20px;
                  @include set_font(18px, 300, 42px);
                }
                .update-item--edit {
                  .icon-edit {
                    font-size: 18px;
                    color: #f9623e;
                  }
                }
              }
              .update-form--label {
                margin: 9px 0 34px 40px;
                @include flex(flex-start, center);
                .update-label {
                  @include flex(flex-start, center);
                  &:not(:last-child) {
                    margin-right: 20px;
                  }
                  .custom-checkbox {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    @include set_font(18px, 300, 20px);
                    user-select: none;
                    padding-left: 30px;
                    margin-bottom: 10px;
                    .label-checkbox {
                      position: absolute;
                      opacity: 0;
                      cursor: pointer;
                      width: 0;
                      height: 0;
                    }
                    .checkmark {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 20px;
                      height: 20px;
                      border: 1px solid #565d6d;
                      border-radius: 3px;
                      background-color: #fff;
                      transition: all 0.3s ease;
                      .icon-checkbox {
                        opacity: 0;
                      }
                    }
                    .checkmark.checked {
                      @include flex(center, center);
                      border: 1px solid #5113d7;
                      background-color: #5113d7;
                      .icon-checkbox {
                        opacity: 1;
                        font-weight: 300;
                        color: #fff;
                      }
                    }
                    .label-checkbox:checked ~ .checkmark {
                      @include flex(center, center);
                      border: 1px solid #5113d7;
                      background-color: #5113d7;
                      .icon-checkbox {
                        opacity: 1;
                        font-weight: 300;
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
          .update-form--action {
            @include flex(center, center);
            .update-action--update {
              margin: 0 15px;
              padding: 10px 12px;
              border-radius: 6px;
              @include set_font(18px, 300, 28px);
              background-color: #6d31ed;
              color: #fff;
              transition: all 0.3s ease;
              &:hover {
                background-color: #5113d7;
              }
            }
            .update-action--next {
              margin: 0 15px;
              padding: 10px 12px;
              border-radius: 6px;
              @include set_font(18px, 300, 28px);
              background-color: #6d31ed;
              color: #fff;
              transition: all 0.3s ease;
              &:hover {
                background-color: #5113d7;
              }
            }

            .update-action--next.disable {
              background-color: #dee1e6;
              cursor: not-allowed;
            }

            .update-form--textbtn {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
.iziToast-wrapper {
  top: 74px;
  right: -1px;
  .iziToast-capsule {
    .iziToast {
      min-height: unset;
      padding: 0;
      border-radius: 4px;
      background: #fff;
      background-color: #fff;
      .iziToast-body {
        margin-left: 0;
        padding: 0;
        padding-left: 0 !important;
        @include flex(flex-start, center);
        border-radius: 4px;
        box-shadow: 1px -1px 5px #dbdbdb;
        overflow: hidden;
        background-color: #fff;
        .iziToast-icon {
          width: unset;
          height: unset;
          position: initial;
          margin-top: 0;
          padding: 14px 8px;
          border-radius: 4px 0 0 4px;
          @include set_font(32px, initial, unset);
          line-height: unset !important;
          background-color: #b8f5cd;
          color: #17a948;
        }
        .iziToast-texts {
          margin: 0 0 0 12px;
          font-family: "Lexend", sans-serif;
          .iziToast-title {
            width: 100%;
            @include set_font(20px, 700, 30px);
          }
          .iziToast-message {
            margin-bottom: 0;
            @include set_font(14px, 400, 22px);
          }
        }
      }
    }
  }
}
.update-item--modal {
  // max-width: unset !important;
  // width: 888px;
  // height: 450px !important;
  // box-shadow: unset;
  .update-modal--header {
    margin-bottom: 28px;
    @include set_font(24px, 700, 36px);
  }
  .update-modal--content {
    .modal-content--category {
      .modal-category {
        @include flex(flex-start, center);
        .add-category {
          width: 90%;
          margin-right: 10px;
          padding: 10px 13px;
          border-radius: 6px;
          background-color: #fff;
          box-shadow: 0px 0px 5px 0px #dbdbdb;
          .add-category--input {
            width: 100%;
            padding: 8px 12px;
            border: 1px solid #9095a1;
            border-radius: 6px;
            @include set_font(14px, 400, 22px);
            transition: all 0.3s ease;
            &:hover {
              border-color: #000;
            }
            &:focus {
              border-color: #000;
            }
            &:disabled {
              color: #bdc1ca;
            }
          }
        }
        .add-label--button {
          width: 166px;
          padding: 8px;
          border-radius: 6px;
          @include set_font(18px, 300, 28px);
          background-color: #6d31ed;
          color: #fff;
          transition: all 0.3s ease;
          &:hover {
            background-color: #5113d7;
          }
          .add-label--icon {
            margin-right: 5px;
          }
        }
      }
      .modal-label--list {
        margin: 14px 0 14px 53px;
        .modal-label--item {
          @include flex(flex-start, center);
          margin-bottom: 14px;
          .modal-label--input {
            width: calc(78% - 2px);
            margin: 0 10px;
            padding: 10px 13px;
            border-radius: 6px;
            box-shadow: 0px 0px 5px 0px #dbdbdb;
            background-color: #fff;
            .modal-label--field {
              width: 100%;
              padding: 8px 12px;
              border: 1px solid #9095a1;
              border-radius: 6px;
              @include set_font(14px, 400, 22px);
              transition: all 0.3s ease;
              &:hover {
                border-color: #000;
              }
              &:disabled {
                color: #bdc1ca;
                border-color: #9095a1;
              }
            }
          }
          .remove-label--button {
            .remove-label--icon {
              @include set_font(32px, 500, initial);
              color: #de3b40;
            }
          }
        }
      }
    }
    .update-modal--action {
      margin-top: 34px;
      @include flex(flex-end, center);
      .update-action--cancel {
        margin: 0 9px;
        padding: 9px 16px;
        border: 1px solid #9095a1;
        border-radius: 4px;
        @include set_font(16px, 300, 26px);
        transition: all 0.3s ease;
        &:hover {
          color: #565d6d;
          border-color: #000;
        }
      }
      .update-action--upgrade {
        margin: 0 9px;
        padding: 9px 16px;
        border: 1px solid #9095a1;
        border-radius: 4px;
        @include set_font(16px, 300, 26px);
        background-color: #6d31ed;
        color: #fff;
        transition: all 0.3s ease;
        &:hover {
          background-color: #5113d7;
        }
        .update-action--icon {
          margin-right: 5px;
        }
      }
    }
  }
}
.list-name {
  padding: 24px 29px 46px;
  border: 1px solid #dee1e6;
  border-radius: 6px;
  background-color: #fafafb;
  .list-name--header {
    margin-bottom: 24px;
    @include set_font(30px, 700, 46px);
  }
  // .list-name--content {
  //   padding: 5px;
  //   .list-name--item {
  //     @include flex(space-between, normal);
  //     margin-bottom: 20px;
  //     .name-item--button {
  //       width: 45%;
  //       padding: 7px 16px;
  //       border-radius: 6px;
  //       @include set_font(20px, 300, 30px);
  //       background-color: #dee1e6;
  //       color: #323743;
  //       transition: all 0.3s ease;
  //       &:hover {
  //         background-color: #57b612;
  //         color: #fff;
  //       }
  //     }
  //     .name-item--button.active {
  //       background-color: #57b612;
  //       color: #fff;
  //     }
  //   }
  // }
  .list-name--content {
    padding: 5px;
    @include flex(space-between, normal);
    flex-wrap: wrap;
    .list-name--item {
      width: 45%;
      margin-bottom: 20px;
      .name-item--button {
        width: 100%;
        @include flex(center, center);
        padding: 7px 16px;
        border-radius: 6px;
        @include set_font(18px, 300, 30px);
        background-color: #dee1e6;
        color: #323743;
        transition: all 0.3s ease;
        &:hover {
          background-color: #57b612;
          color: #fff;
        }
        .name-button--text {
          max-width: 250px;
          @include truncate_text(1);
        }
      }
      .name-item--button.active {
        background-color: #57b612;
        color: #fff;
      }
    }
  }
  .list-name--pagination {
    @include flex(flex-end, center);
    margin-top: 40px;
    .name-pagination--item {
      &:not(:last-child) {
        margin-right: 4px;
      }
      .pagination-item--control {
        padding: 10px;
        @include set_font(14px, 300, 22px);
        color: #424856;
        .pagination-item--icon {
          font-size: 16px;
        }
      }
      .pagination-item--number {
        width: 36px;
        padding: 10px;
        border: 1px solid #dee1e6;
        border-radius: 4px;
        color: #9095a1;
        transition: all 0.3s ease;
        &:hover {
          border: 1px solid #6d31ed;
          background-color: #6d31ed;
          color: #fff;
        }
      }
      .pagination-item--number.active {
        border: 1px solid #6d31ed;
        background-color: #6d31ed;
        color: #fff;
      }
    }
  }
}
.list-image {
  margin-top: 40px;
  padding: 24px 29px 101px;
  border: 1px solid #dee1e6;
  border-radius: 6px;
  background-color: #fafafb;
  // min-height: 627px;

  .pagination-list--image {
    padding-top: 30px;
  }

  .list-image--header {
    margin-bottom: 24px;
    @include set_font(30px, 700, 46px);
  }
  .list-image--content {
    border-radius: 4px;
    border: 1px solid #dee1e6;
    .list-image--table {
      width: 100%;
      .image-table--row {
        border-bottom: 1px solid #d9d9d9;

        &:nth-last-child(-n + 1) {
          border-bottom: none;
        }
        &:nth-child(2) {
          .table-image {
            background-color: #b9e5ff;
          }
        }
        &:nth-child(3) {
          .table-image {
            background-color: #ffc9e2;
          }
        }
        &:nth-child(4) {
          .table-image {
            background-color: #fff0af;
          }
        }
        &:nth-child(5),
        &:nth-child(6) {
          .table-image {
            background-color: #fdcfc4;
          }
        }
        &:nth-child(7) {
          .table-image {
            background-color: #f3f4f6;
          }
        }
        .align-left {
          text-align: left !important;
        }
        .image-table--head {
          padding: 12px 12px;
          @include set_font(14px, 600, 22px);
          color: #565d6d;
          &:first-child {
            padding-left: 15px;
          }
          &:last-child {
            padding-right: 15px;
          }
        }
        .image-table--item {
          padding: 15px 10px;
          text-align: center;
          @include set_font(14px, 300, 22px);
          color: #171a1f;
          &:first-child {
            padding-left: 15px;
          }
          &:last-child {
            padding-right: 15px;
          }
          .table-item--image {
            @include flex(center, center);
            .table-image {
              width: 44px;
              height: 44px;
              border-radius: 4px;
              overflow: hidden;

              .img-avt {
                cursor: pointer;
              }
            }
          }
          .table-item--name {
            max-width: 200px;
            @include truncate_text(1);
            font-weight: 600 !important;
          }
          .tag-name--list {
            @include flex(center, center);
            .tag-name--item {
              padding: 8px 10px;
              border-radius: 14px;
              background-color: #f5f1fe;
              color: #6d31ed;
              &:not(:last-child) {
                margin-right: 5px;
              }
              .tag-name--text.has-tag {
                max-width: 80px;
                @include truncate_text(1);
                @include set_font(12px, 400, 20px);
              }
            }
            .tag-name--notag {
              width: 28px;
              height: 28px;
              padding: 4px 6px;
              border-radius: 14px;
              background-color: #fdf2f2;
              .no-tag--icon {
                font-size: 16px;
                color: #de3b40;
              }
            }
          }
          .tag-status {
            @include flex(center, center);
            .tag-status--icon {
              @include flex(center, center);
              width: 20px;
              height: 20px;
              border-radius: 100%;
              background-color: #62cd14;
              color: #fff;
            }
            .tag-status--icon.status-bad {
              background-color: #de3b40;
            }
          }
        }
        .image-table--item.image {
          @include flex(center, center);
        }
      }
    }
  }
  .list-image--pagination {
    @include flex(flex-end, center);
    margin-top: 40px;
    .image-pagination--item {
      &:not(:last-child) {
        margin-right: 4px;
      }
      .pagination-item--control {
        padding: 10px;
        @include set_font(14px, 300, 22px);
        color: #424856;
        .pagination-item--icon {
          font-size: 16px;
        }
      }
      .pagination-item--number {
        width: 36px;
        padding: 10px;
        border: 1px solid #dee1e6;
        border-radius: 4px;
        color: #9095a1;
        transition: all 0.3s ease;
        &:hover {
          border: 1px solid #6d31ed;
          background-color: #6d31ed;
          color: #fff;
        }
      }
      .pagination-item--number.active {
        border: 1px solid #6d31ed;
        background-color: #6d31ed;
        color: #fff;
      }
    }
  }
}

.hasShadow {
  &::after {
    display: none;
  }
}
