.ant-pagination {
  float: right;
  display: flex;

  .ant-pagination-item-active:hover {
    border-color: none;
  }

  .ant-pagination-item {
    min-width: 31px;
    height: 36px;
    // padding: 0 12px;
    text-align: center;
    @include flex(center, center);
    line-height: 22px;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid #dee1e6;
    margin-inline-end: 4px;

    a {
      font-size: 14px;
      color: #9095a1;
    }
  }
  .ant-pagination-item:not(.ant-pagination-item-active):hover {
    background-color: #ffffff;
  }
  .ant-pagination-item.ant-pagination-item-active {
    background-color: #3f0ea6;
    a {
      color: #ffffffff !important;
    }
  }
}
